<template>
  <base-modal
    v-if="!isEmailListLinkSubmitted && !category && show"
    @close="close"
    :loading="submitting || loading"
    title="Autoplug Conditions"
  >
    <div class="space-y-6 p-6" v-if="!hasEmailList">
      <span>Do you have an email list?</span>
      <div class="mt-3 grid grid-cols-2 items-center space-x-2">
        <new-button data-cy="email-list-no" @click="displayAutoplugSettings" type="secondary">
          <span class="save-autoplug">No</span>
        </new-button>
        <new-button @click="hasEmailList = true" data-cy="email-list-yes">
          <span class="save-autoplug">Yes</span>
        </new-button>
      </div>
    </div>
    <div class="space-y-6" v-else>
      <span>Enter link to your email list:</span>
      <base-input
        @keyup.enter="displayAutoplugSettings"
        type="text"
        placeholder="Enter link"
        ref="email_list_link_input"
        v-model="emailListLink"
        data-cy="email-list-input"
      />
      <div class="mt-3 flex items-center justify-end space-x-2">
        <base-button
          :disabled="!isEmailListLinkValid"
          @click="displayAutoplugSettings"
          type="primary"
          class="btn text primary w-full lg:w-auto"
          data-cy="email-list-done"
        >
          <span class="save-autoplug">Done</span>
        </base-button>
      </div>
    </div>
  </base-modal>

  <base-modal v-else-if="show" :loading="submitting || loading" @close="close">
    <div class="card-container rounded-3xl p-8">
      <div class="grid gap-6">
        <div class="flex items-center justify-between">
          <div class="flex items-center gap-2">
            <div>
              <inline-svg class="h-6 w-6" src="/img/icons/autoplugs-icon.svg" />
            </div>
            <span data-cy="autoplug-header" class="text-2xl font-bold"
              >{{ category ? 'Category' : 'Twitter' }} Autoplug</span
            >
          </div>
          <button data-cy="autoplug-close" @click="$emit('close')">
            <inline-svg src="/img/icons/close.svg" />
          </button>
        </div>

        <new-alert class="my-2" v-if="userProfile.customerStatus === 'none'">
          Autoplugs are one of the many perks of being a Hypefury subscriber.<br />
          You can set them up, but they won't work unless you
          <router-link to="/billing">grab a Hypefury subscription</router-link>.
        </new-alert>

        <p data-cy="autoplug-description" class="text-lg">
          Automatically add a follow-up comment when your post hits a certain engagement level that
          you define.
        </p>
        <div class="grid gap-6">
          <div v-if="category && !autoplugEnabled" class="mb-4 flex items-center justify-between">
            <div class="flex items-center">
              <label
                for="multiple"
                class="app-modal-text mb-2 block text-base font-medium text-black"
                >Enable Autoplugs {{ category && `for ${category.name} Category` }}</label
              >
            </div>
            <base-toggle data-cy="autoplug-switch" v-model="autoplugEnabled" />
          </div>

          <new-tabs v-else defaultTabHash="#simple" tabStyle="underlined">
            <new-tab label="Simple" id="autoplug-simple">
              <div class="grid gap-6">
                <div class="space-y-3">
                  <label
                    for="multiple"
                    class="app-modal-text mb-2 block text-base font-medium text-black"
                    >Add multiple autoplugs to be randomly cycled.</label
                  >
                  <div
                    v-for="(status, idx) in autoplugStatus"
                    :key="idx"
                    style="margin-bottom: -4px"
                  >
                    <vue-tribute :options="tributeOptions" class="container-bg relative">
                      <textarea
                        data-cy="autoplug-textarea"
                        v-model="status.content"
                        :ref="`autoplug-textarea-${idx}`"
                        class="form-field pb-2"
                        placeholder="Mention your mailing list, your ecommerce store, or anything else."
                        rows="4"
                        @input="resize(`autoplug-textarea-${idx}`)"
                      />

                      <div class="mx-auto mb-1.5" :ref="`mediaContainer-${idx}`">
                        <portal-target
                          :name="`uploadContainerPortal-${idx}`"
                          class="space-y-1.5"
                          multiple
                        >
                        </portal-target>
                      </div>

                      <div class="flex items-center justify-end space-x-2 text-lg">
                        <upload-container
                          :tweet="status"
                          :canAddMedia="canAddMedia(status)"
                          :canAddMoreVideos="canAddMoreVideos"
                          :portalTargetName="`uploadContainerPortal-${idx}`"
                          :clearMediaSelectionAtIndexParent="clearMediaSelectionAtIndex"
                          :imageCountInThread="imageCountInThread"
                          :uploadingMedia="uploadingMedia"
                          containerSize="sm"
                        />
                        <span>
                          <character-counter
                            :maximumCharacters="tweetCharactersLimit"
                            :textLength="tweetLength(status)"
                          />
                        </span>
                        <tooltip content="Remove Tweet" v-if="canDeleteAutoplugTweet">
                          <new-button
                            type="custom"
                            data-cy="remove-tweet"
                            @click="deleteAutoplugTweet(idx)"
                          >
                            <inline-svg src="/img/icons/close-circle.svg" />
                          </new-button>
                        </tooltip>
                      </div>
                    </vue-tribute>
                  </div>
                </div>
                <div class="flex">
                  <new-button data-cy="add-autoplug" @click="addAutoplugTweetBox">
                    Add an alternative
                  </new-button>
                </div>
              </div>
            </new-tab>
            <new-tab label="Advanced" id="autoplug-advanced">
              <div class="grid gap-6">
                <div v-if="category" class="flex items-center justify-between">
                  <div class="flex items-center">
                    <label
                      for="multiple"
                      class="app-modal-text mb-2 block text-base font-medium text-black"
                      >Enable Autoplugs {{ category && `for ${category.name} Category` }}</label
                    >
                  </div>
                  <base-toggle data-cy="autoplug-switch" v-model="autoplugEnabled" />
                </div>

                <div
                  v-if="
                    postedTweetsLikes &&
                    postedTweetsLikes.length &&
                    postedTweetsRetweets &&
                    postedTweetsRetweets.length
                  "
                >
                  <div class="mb-2 flex space-x-4">
                    <span data-cy="autoplug-threshold"> {{ pluggableTweetsThreshold }}% </span>
                    <div class="w-1/3">
                      <vue-slider
                        :interval="10"
                        v-model="pluggableTweetsThreshold"
                        :min="0"
                        :max="100"
                        :lazy="true"
                        data-cy="autoplug-slider"
                      ></vue-slider>
                    </div>
                  </div>
                  <span class="dark-mode-text-15 text-base font-medium text-gray-80"
                    >Apply autoplug to top {{ pluggableTweetsThreshold }}% most popular
                    tweets.</span
                  >
                </div>
                <div>
                  <label
                    for="multiple"
                    class="app-modal-text mb-2 block text-base font-medium text-black"
                    >Only autoplug if the post gets</label
                  >
                  <div class="grid grid-cols-2 gap-4">
                    <base-number-input
                      v-if="conditionForAutoplug === 'Retweets'"
                      data-cy="autoplug-min-retweets"
                      min="0"
                      name="autoplugMinRetweets"
                      v-model="autoplugMinRetweets"
                    />
                    <base-number-input
                      v-if="conditionForAutoplug === 'Likes'"
                      data-cy="autoplug-min-likes"
                      type="number"
                      min="0"
                      name="autoplugMinFavorites"
                      v-model="autoplugMinFavorites"
                    />
                    <div class="relative">
                      <base-select
                        data-cy="autoplug-condition-select"
                        placeholder="Select an Option"
                        id="conditionForAutoplug"
                        v-model="conditionForAutoplug"
                        :options="conditionForAutoplugOptions"
                      />
                    </div>
                  </div>
                </div>
                <div class="flex items-center justify-between">
                  <span class="app-modal-text text-base font-medium text-gray-80"
                    >Don’t autoplug if I manually replied</span
                  >
                  <div>
                    <base-toggle data-cy="autoplug-delay-switch" v-model="isAutoplugDelayEnabled" />
                  </div>
                </div>

                <div
                  v-if="isAutoplugDelayEnabled"
                  class="form-group flex items-center justify-between pb-6"
                >
                  <label class="form-control-label pr-2 lg:w-1/2">
                    <span v-if="showAutoplugDelayError" class="text-badge-error">
                      Delay should be between 1 and 12
                    </span>
                    <span v-else>Autoplug if no reply within</span>
                  </label>

                  <div class="relative mt-2 flex items-center space-x-2 lg:mt-0 lg:w-1/2">
                    <base-number-input
                      type="number"
                      data-cy="autoplug-delay-input"
                      v-model="autoplugDelay"
                      name="autoplugDelay"
                    />
                    <span>Hours</span>
                  </div>
                </div>

                <!---
                <div class="flex items-center justify-between">
                  <span class="app-modal-text text-base font-medium text-gray-80"
                    >Prioritize global autoplugs over tweet-specific</span
                  >
                  <div>
                    <base-toggle data-cy="prefer-global-switch" v-model="preferGlobalAutoplugSettings" />
                  </div>
                </div>
              --->
              </div>
            </new-tab>
          </new-tabs>
        </div>
        <div class="flex items-center justify-end">
          <new-button
            size="md"
            :disabled="!areAutoplugConditionsValid"
            @click="saveAutoplugConditions"
            data-cy="autoplug-save"
          >
            Save{{shouldEnableAutoplugsWhileSaving ? ' & Enable' : ''}}
          </new-button>
        </div>
      </div>
    </div>
  </base-modal>
</template>

<script>
  import lodash from 'lodash';
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  import dao from '@/dao';
  import { countTweetLength } from '@/../functions/src/util/countTweetLength';
  import TaggingMixin from '../../views/Mixins/TaggingMixin';
  import NewThreadMixin from '../../views/Mixins/NewThreadMixin';
  import { mapGetters, mapState } from 'vuex';
  import storage from '@/storage';
  import { store } from '@/store';
  import VueSlider from 'vue-slider-component';
  import UploadContainer from '../../components/UploadContainer.vue';
  import UploadContainerMixin from '@/views/Mixins/UploadContainerMixin';
  import 'vue-slider-component/theme/antd.css';
  import NewTabs from '@/components/Tabs/NewTabs.vue';
  import NewTab from '@/components/Tabs/NewTab.vue';
  import CharacterCounter from '../../components/CharacterCounter.vue';
  import CustomerStatusMixin from '../Mixins/CustomerStatusMixin.vue';
  import { EventBus } from '@/event-bus';
  import TweetCharactersLimitMixin from '../Mixins/TweetCharactersLimitMixin.vue';
  const config = require('@/config');

  export default {
    components: {
      VueSlider,
      NewTabs,
      NewTab,
      UploadContainer,
      CharacterCounter,
    },
    props: {
      show: Boolean,
      category: Object,
      shouldEnableAutoplugsWhileSaving: Boolean,
    },
    name: 'autoplug-modal',
    data() {
      return {
        autoplugEnabled: false,
        autoplugMinFavorites: 20,
        autoplugMinRetweets: 20,
        autoplugStatus: [{ content: '', media: [], mediaFile: [], fileList: [] }],
        hasModalJustOpened: false,
        templateNameInput: '',
        selectedTemplate: null,
        templateBeingEdited: null,
        wasTemplateEdited: false,
        submitting: false,
        loading: false,
        preferGlobalAutoplugSettings: false,
        conditionForAutoplug: 'Likes',
        conditionForAutoplugOptions: ['Retweets', 'Likes'],
        isAutoplugDelayEnabled: false,
        autoplugDelay: 1,
        showAutoplugDelayError: false,
        emailListLink: '',
        hasEmailList: false,
        isEmailListLinkSubmitted: false,
        activeTab: 'simple',
        uploadingMedia: [],
      };
    },
    mounted() {
      EventBus.$on('set-uploading-media', (i) => {
        this.uploadingMedia = [...this.uploadingMedia, i];
      });

      EventBus.$on('unset-uploading-media', (i) => {
        this.uploadingMedia = [...this.uploadingMedia.filter((idx) => idx !== i)];
      });
    },
    created() {
      if (this.isFirstTimeUser) {
        this.pluggableTweetsThreshold = 20;
      }
    },
    methods: {
      resize(refName) {
        const autoPlugTextArea = this.$refs[refName] ? this.$refs[refName][0] : null;
        if (autoPlugTextArea && autoPlugTextArea.scrollHeight - autoPlugTextArea.offsetHeight > 20) {
          autoPlugTextArea.style.height = autoPlugTextArea.scrollHeight + 4 + 'px';
        }
      },
      close() {
        this.emailListLink = '';
        this.hasEmailList = false;
        this.$emit('close');
      },
      canAddMedia(status) {
        return (
          status.mediaFile &&
          (status.mediaFile.length === 0 ||
            (status.mediaFile.length < 4 &&
              !status.mediaFile[0].type.includes('gif') &&
              !status.mediaFile[0].type.includes('video')))
        );
      },
      addAutoplugTweetBox() {
        this.autoplugStatus = [
          ...this.autoplugStatus,
          {
            content: '',
            media: [],
            fileList: [],
            mediaFile: [],
            count: this.autoplugStatus.length,
          },
        ];
      },
      clearMediaSelectionAtIndex(status, i) {
        this.autoplugStatus = this.autoplugStatus.map((s, index) => {
          if (status.count === index) {
	    const mediaName = s.mediaFile[i].name;
	    const mediaThumbnail = s.mediaFile[i].thumbnail;
	    this.setMediaToDelete(mediaName, mediaThumbnail);
	    return {
              ...s,
              mediaFile: s.mediaFile.filter(({ name }) => mediaName !== name),
	      fileList: s.fileList.filter(({ name }) => mediaName !== name),
	      media: s.media.filter(({ name }) => mediaName !== name),
            };
          } else {
            return s;
          }
        });
      },
      async saveAutoplugConditions() {
        this.submitting = true;

        const statuses = this.autoplugStatus.map((tweet) => {
          const updatedTweet = {...tweet};
          delete updatedTweet.fileList;
          delete updatedTweet.mediaFile;
          return updatedTweet;
        });

        const autoplugConditions = {
          // For the new UI, Autoplugs will be set by the toggler so we won't save it here, except
          // for categories or the shouldEnableAutoplugsWhileSaving prop is true
          ...lodash.get(this.userProfile, 'settings.autoplugConditions', {}),
          ...(!this.category ? {} : { autoplugEnabled: this.autoplugEnabled }),
          minFavorites:
            this.conditionForAutoplug === 'Likes' ? Number(this.autoplugMinFavorites) : null,
          minRetweets:
            this.conditionForAutoplug === 'Retweets' ? Number(this.autoplugMinRetweets) : null,
          status: statuses.map((status) => {
            delete status.count;
            return status;
          }),
          autoplugDelay: this.isAutoplugDelayEnabled ? Number(this.autoplugDelay) : null,
        };

        if (this.shouldEnableAutoplugsWhileSaving) {
          autoplugConditions.autoplugEnabled = true;
        }

        try {
          if (this.category) {
            await dao.userProfile.saveCategoryAutoplugConditions(
              this.userProfile.uid,
              autoplugConditions,
              this.category.id,
            );
          } else {
	    const operations = [];
            operations.push(
	      dao.userProfile.saveAutoplugConditionsToFirestore(
		this.userProfile.uid,
		autoplugConditions
              )
	    );
	    if (this.mediaToDelete.length) {
              operations.push(
		storage.deleteOldMedia([this.mediaToDelete.filter((m) => m)])
	      );
	    }
	    await Promise.all(operations);
	    this.mediaToDelete = [];

            // await dao.userProfile.updatePreferGlobalAutoplugSettings(
            //   this.userProfile.uid,
            //   this.autoplugEnabled ? this.preferGlobalAutoplugSettings : false
            // );
          }

          this.$notify({ type: 'success', message: 'Autoplug conditions updated!' });
        } catch (error) {
          this.swalModal({
            title: 'Error',
            text: `Sorry, an error has occurred while trying to update the autoplug conditions.`,
            type: 'error',
          });
        }
        this.submitting = false;
        this.close();
      },
      getValuesFromUserProfile() {
        const autoplugConditionsToUse = (() => {
          if (this.category) {
            return lodash.get(this, `userProfile.settings.categoriesAutoplugs.categories.${this.category.id}`);
          } else {
            const oldAutoplugConditions = lodash.get(this, 'userProfile.autoplugConditions');
            const newAutoplugConditions = lodash.get(this, 'userProfile.settings.autoplugConditions');
            return newAutoplugConditions || oldAutoplugConditions;
          }
        })();

        if (!autoplugConditionsToUse) {
          return;
        }

        this.autoplugMinFavorites = autoplugConditionsToUse.minFavorites;
        this.autoplugMinRetweets = autoplugConditionsToUse.minRetweets;
        this.autoplugStatus = (() => {
          if (Array.isArray(autoplugConditionsToUse.status)) {
            return autoplugConditionsToUse.status.map((status, index) => {
              const mediaFile = [];
              if (status.media && status.media.length) {
                status.media.forEach((media) => {
                  const mediaName = media.name;
                  const mediaType = media.type;
                  const mediaAltText = media.altText;
                  mediaFile.push({
                    name: mediaName,
                    type: this.getMediaType(mediaType),
                    mimeType: mediaType,
                    url: config.buildStorageMediaURL(mediaName),
                    altText: mediaAltText,
                  });
                });
              }
              return {
                ...status,
                mediaFile: mediaFile,
                fileList: [],
                media: status.media || [],
                count: index,
              };
            });
          } else {
            const mediaFile = [];
            if (
              autoplugConditionsToUse.status &&
              autoplugConditionsToUse.status.media &&
              autoplugConditionsToUse.status.media.length
            ) {
              autoplugConditionsToUse.status.media.forEach((media) => {
                const mediaName = media.name;
                const mediaType = media.type;
                const mediaAltText = media.altText;
                mediaFile.push({
                  name: mediaName,
                  type: this.getMediaType(mediaType),
                  mimeType: mediaType,
                  url: config.buildStorageMediaURL(mediaName),
                  altText: mediaAltText,
                });
              });
            }
            return [
              {
                ...autoplugConditionsToUse.status,
                mediaFile: mediaFile,
                fileList: autoplugConditionsToUse.status ? autoplugConditionsToUse.status.fileList : [],
                media: autoplugConditionsToUse.status ? autoplugConditionsToUse.status.media : [],
                count: 0,
              },
            ];
          }
        })();
        this.autoplugEnabled = autoplugConditionsToUse.autoplugEnabled;
        this.isAutoplugDelayEnabled =
          !isNaN(autoplugConditionsToUse.autoplugDelay) && autoplugConditionsToUse.autoplugDelay > 0
            ? true
            : false;
        this.autoplugDelay = autoplugConditionsToUse.autoplugDelay || 1;
      },
      deleteAutoplugTweet(tweetIndex) {
        const mediaFile = lodash.get(this.autoplugStatus[tweetIndex], 'mediaFile', []);
        mediaFile.forEach(({ name, thumbnail }) => {
          this.setMediaToDelete(name, thumbnail);
        });
        this.autoplugStatus = this.autoplugStatus.filter((_, idx) => idx !== tweetIndex);
      },
      getCharactersLeftCount(autoplugStatus) {
        return this.tweetCharactersLimit - countTweetLength(autoplugStatus);
      },
      getCharactersLeftClass(autoplugStatus) {
        const statusLength = countTweetLength(autoplugStatus);
        if (statusLength >= this.tweetCharactersLimit) return 'character-count-exceeded';
        if (statusLength > this.tweetCharactersLimit - 20) return 'character-count-warning';
      },
      displayAutoplugSettings() {
        this.isEmailListLinkSubmitted = true;
        if (this.isEmailListLinkValid) {
          this.autoplugStatus = [
            {
              count: 0,
              fileList: [],
              media: [],
              mediaFile: [],
              content:
                `If you like this tweet, you will love my exclusive emails for my subscribers.` +
                `\n\nJoin here: ${this.emailListLink}`,
            },
          ];
        }
        return;
      },
      updateMinRetweets(value) {
        this.autoplugMinRetweets = value;
      },
      updateMinFavorites(value) {
        this.autoplugMinFavorites = value;
      },
      onModalVisible(show = true) {
        if (show) {
          if (this.$refs.tabsModal) {
            this.$refs.tabsModal.selectTab(0);
          }

          if (this.$refs[`textarea-${this.autoplugStatus.length - 1}`]) {
            setTimeout(() => this.$refs[`textarea-${this.autoplugStatus.length - 1}`][0].focus(), 0);
          }

          if (this.postedTweetsLikes === null || this.postedTweetsRetweets === null) {
            this.loading = true;
            store.dispatch('fetchPostedTweetsLikesAndRetweets');
          }

          if (!this.isFirstTimeUser || this.category) {
            this.isEmailListLinkSubmitted = true;
            this.getValuesFromUserProfile(this.userProfile);
            // this.preferGlobalAutoplugSettings = lodash.get(
            //   this.userProfile,
            //   'settings.preferGlobalAutoplugSettings',
            //   false
            // );

            if (!lodash.isNil(this.autoplugMinRetweets)) {
              this.conditionForAutoplug = 'Retweets';
              this.autoplugMinFavorites = 0;
            } else if (!lodash.isNil(this.autoplugMinFavorites)) {
              this.conditionForAutoplug = 'Likes';
              this.autoplugMinRetweets = 0;
            } else {
              this.conditionForAutoplug = 'Likes';
              this.autoplugMinFavorites = 0;
              this.autoplugMinRetweets = 0;
            }
          }
        }
      },
      tweetLength(status) {
        return countTweetLength(status.content);
      },
    },
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
      ...mapState(['postedTweetsLikes', 'postedTweetsRetweets']),
      canAddMoreVideos() {
        return Boolean(
          this.autoplugStatus.filter(
            (s) => s.mediaFile[0] && s.mediaFile[0].type.includes('video').length < 10
          )
        );
      },
      textareaRefKey() {
        return 'autoplug-textarea';
      },
      imageCountInThread() {
        const count = this.autoplugStatus.reduce(
          (acc, val) =>
            acc +
            val.mediaFile.filter((media) => media.type.toLowerCase().includes('image')).length,
          0
        );
        return count;
      },
      wereAutoplugConditionsJustEnabled() {
        return (
          !lodash.get(this.userProfile, 'settings.autoplugConditions.autoplugEnabled', false) &&
          this.autoplugEnabled === true
        );
      },
      areAutoplugConditionsValid() {
        // For the new UI, Autoplugs will be set by the toggler we always assume that it's enabled (except for categories)
        if (this.autoplugEnabled || !this.category) {
          const areMinFavoritesConditionsValid =
            this.conditionForAutoplug === 'Likes' &&
            !lodash.isNil(this.autoplugMinFavorites) &&
            Number(this.autoplugMinFavorites) >= 0;

          const areMinRetweetsConditionsValid =
            this.conditionForAutoplug === 'Retweets' &&
            !lodash.isNil(this.autoplugMinRetweets) &&
            Number(this.autoplugMinRetweets) >= 0;

          return (
            (areMinFavoritesConditionsValid || areMinRetweetsConditionsValid) &&
            this.autoplugStatus &&
            this.autoplugStatus.length &&
            this.autoplugStatus.every((status) => {
              const content = status.content;
              return (
                content &&
                content.trim().length > 0 &&
                countTweetLength(content.trim()) <= this.tweetCharactersLimit
              );
            }) &&
            (!this.isAutoplugDelayEnabled ||
              (!isNaN(this.autoplugDelay) &&
                Number(this.autoplugDelay) > 0 &&
                Number(this.autoplugDelay) <= 12)) &&
            this.uploadingMedia.length === 0
          );
        }
        return true;
      },
      canDeleteAutoplugTweet() {
        return Array.isArray(this.autoplugStatus) && this.autoplugStatus.length > 1;
      },
      isEmailListLinkValid() {
        return this.emailListLink.trim().length > 0;
      },
      isFirstTimeUser() {
        return !lodash.get(this.userProfile, 'settings.autoplugConditions');
      },
      pluggableTweetsThreshold: {
        get: function () {
          if (this.conditionForAutoplug === 'Likes' && this.postedTweetsLikes) {
            const tweetsWithSameOrHigherLikesCount = this.postedTweetsLikes.filter(
              (likes) => likes >= Number(this.autoplugMinFavorites)
            );

            const value = Math.ceil(
              (tweetsWithSameOrHigherLikesCount.length / this.postedTweetsLikes.length) * 100
            );

            return value;
          } else if (this.conditionForAutoplug === 'Retweets' && this.postedTweetsRetweets) {
            const tweetsWithSameOrHigherRetweetCount = this.postedTweetsRetweets.filter(
              (retweets) => retweets >= Number(this.autoplugMinRetweets)
            );

            const value = Math.ceil(
              (tweetsWithSameOrHigherRetweetCount.length / this.postedTweetsRetweets.length) * 100
            );

            return value;
          } else {
            return null;
          }
        },
        set: function (val) {
          if (
            (this.conditionForAutoplug === 'Likes' && this.postedTweetsLikes === null) ||
            (this.conditionForAutoplug === 'Retweets' && this.postedTweetsRetweets === null)
          ) {
            return;
          }

          const values =
            this.conditionForAutoplug === 'Likes'
              ? this.postedTweetsLikes
              : this.postedTweetsRetweets;

          const index = Math.ceil((values.length / 100) * val);

          const value = index >= values.length ? values[index - 1] : values[index];

          // If likes or retweets were updated manually we don't want to override them
          // Rather we want to sync the bar with the values, not the other way around
          if (this.conditionForAutoplug === 'Likes') {
            if (this.autoplugMinFavorites !== value) {
              this.autoplugMinFavorites = value;
            }
          } else {
            if (this.autoplugMinRetweets !== value) {
              this.autoplugMinRetweets = value;
            }
          }
        },
      },
    },
    mixins: [
      TaggingMixin,
      SwalModalMixin,
      UploadContainerMixin,
      NewThreadMixin,
      CustomerStatusMixin,
      TweetCharactersLimitMixin,
    ],
    watch: {
      show(val) {
        this.onModalVisible(val);
      },
      hasEmailList(val) {
        if (val) {
          this.$nextTick(() => {
            this.$refs.email_list_link_input.$el.firstElementChild.firstElementChild.focus();
          });
        }
      },
      autoplugDelay(val) {
        if (val < 1 || val > 12) {
          this.showAutoplugDelayError = true;
        } else {
          this.showAutoplugDelayError = false;
        }
      },
      autoplugStatus(val) {
        if (val.length > 1) {
          setTimeout(() => this.$refs[`${this.textareaRefKey}-${val.length - 1}`][0].focus(), 200);
        } else if (this.autoplugEnabled === true) {
          setTimeout(() => this.$refs[`${this.textareaRefKey}-0`][0].focus(), 200);
        }
      },
      autoplugEnabled(val) {
        if (val === true) {
          if (this.$refs.tabsModal) {
            this.$refs.tabsModal.selectTab(0);
          }
          setTimeout(
            () => this.$refs[`${this.textareaRefKey}-${this.autoplugStatus.length - 1}`][0].focus(),
            200
          );
        }
      },
      autoplugMinRetweets(val) {
        if (val) {
          this.autoplugMinFavorites = val;
        }
      },
      autoplugMinFavorites(val) {
        if (val) {
          this.autoplugMinRetweets = val;
        }
      },
      postedTweetsLikes(val) {
        if (val === null) {
          return;
        } else {
          this.loading = false;
        }
      },
      postedTweetsRetweets(val) {
        if (val === null) {
          return;
        } else {
          this.loading = false;
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  @media only screen and (max-width: 404px) {
    .sm-with-130 {
      width: 130px;
    }
  }

  .save-autoplug {
    text-align: center !important;
  }
  .new-ui {
    .container-bg {
      @apply border rounded-lg p-2;
    }
    &.mode--dark {
      .container-bg {
        @apply border-dark-mode-60 bg-dark-mode-60;
      }
    }
    &.mode--light {
      .container-bg {
        @apply bg-white border-gray-90;
      }
    }
  }
  .card-container {
    max-width: 466px;
  }
</style>
